<template>
	<div class="pb-1">
		<b-card class="mb-1" no-body>
			<b-card-header class="pb-50">
				<h5>Filters</h5>
			</b-card-header>
			<b-card-body>
				<b-row>
					<b-col cols="12" md="3" class="mb-md-0 mb-2">
						<label>Plan Type</label>
						<v-select v-model="filters.planType" :options="planTypeOptions" :reduce="(val) => val.value" />
					</b-col>
					<b-col cols="12" md="3" class="mb-md-0 mb-2">
						<label>Published Activities</label>
						<v-select v-model="filters.publishedActivities" :options="publishedActivitiesOptions" :reduce="(val) => val.value" />
					</b-col>
					<b-col cols="12" md="3" class="mb-md-0 mb-2">
						<label>Bank Account Added</label>
						<v-select v-model="filters.bankAccountAdded" :options="bankAccountAddedOptions" :reduce="(val) => val.value" />
					</b-col>
					<b-col cols="12" md="3" class="mb-md-0 mb-2">
						<div class="d-flex justify-content-between align-items-center">
							<label>Bookings Received</label>
							<div>
								<b-badge @click="resetBookings" v-if="filters.bookingsReceived.from !== 0 || filters.bookingsReceived.to !== 100" variant="light-primary" class="clear-badge"> Clear </b-badge>
							</div>
						</div>
						<b-row class="d-flex align-items-center">
							<b-col cols="12" md="5">
								<b-form-input v-model="filters.bookingsReceived.from" placeholder="From" />
							</b-col>
							<b-col cols="12" md="2" class="text-center">to</b-col>
							<b-col cols="12" md="5">
								<b-form-input v-model="filters.bookingsReceived.to" placeholder="To" />
							</b-col>
						</b-row>
					</b-col>
				</b-row>
				<b-row class="mt-1">
					<b-col cols="12" md="6" class="mb-md-0 mb-2">
						<div v-if="filters.signedUp === 'custom'">
							<label>SignedUp Date</label>
							<div class="d-flex custom-signedup">
								<v-select v-model="filters.signedUp" :options="signedUpOptions" :reduce="(val) => val.value" class="w-50" />
								<flat-pickr v-model="filters.signedUpCustom" class="form-control" :config="{ mode: 'range' }" placeholder="Select Date" />
							</div>
						</div>
						<div v-else>
							<label>SignedUp Date</label>
							<v-select v-model="filters.signedUp" :options="signedUpOptions" :reduce="(val) => val.value" />
						</div>
					</b-col>
					<b-col cols="12" md="3" class="mb-md-0 mb-2">
						<label>Search</label>
						<b-form-input v-model="filters.search" placeholder="Search by Name or Email" />
					</b-col>
					<b-col cols="12" md="3" class="mb-md-0 mb-2">
						<label>Custom Domain</label>
						<v-select v-model="filters.customDomain" :options="customDomainOptions" :reduce="(val) => val.value" />
					</b-col>
				</b-row>
			</b-card-body>
		</b-card>
		<b-button v-on:click="exportUsers" variant="relief-primary" class="w-100 -mt-2 mb-2" :disabled="loading_export">
			<div v-if="loading_export" class="spinner-border spinner-border-sm" role="status">
				<span class="sr-only">Loading...</span>
			</div>
			<span v-else>Export</span>
		</b-button>
		<div class="card adv-card" v-for="user in users" :key="user.id">
			<router-link :to="'user/' + user.id">
				<div class="card-body">
					<div class="row d-flex justify-content-between align-items-center text-dark">
						<div class="col-11">
							<div class="row">
								<div class="col-md-5">
									<div>
										<strong>{{ user.name }}</strong> - <small>{{ user.created_at | moment("Do MMMM YYYY") }}</small>
									</div>
									<div>{{ user.email }}</div>
								</div>
								<div class="col-md-3 d-flex align-items-center my-1 my-md-0">
									<b-badge v-if="user.plans[user.plans.length - 1]" variant="primary">
										<span v-if="plans[user.plans[user.plans.length - 1].plan_id]">{{ plans[user.plans[user.plans.length - 1].plan_id] }}</span>
										<span v-else>Unknown</span>
									</b-badge>
									<b-badge v-else variant="primary">
										<span>Free</span>
									</b-badge>
								</div>
								<div v-if="user.plans[user.plans.length - 1]" class="col-md-3 d-flex align-items-center my-1 my-md-0">
									<b-badge v-if="user.plans[user.plans.length - 1].status == 'active'" class="ml-1" variant="success">
										<span>Active</span>
									</b-badge>
									<b-badge v-if="user.plans[user.plans.length - 1].status == 'canceled'" class="ml-1" variant="danger">
										<span>Cancelled on {{ user.plans[user.plans.length - 1].updated_at | moment("Do MMMM YYYY") }}</span>
									</b-badge>
								</div>
								<div v-else class="col-md-3 d-flex align-items-center my-1 my-md-0">
									<b-badge class="ml-1" variant="warning">
										<span>Trial Ends on {{ user.trial_ends_at | moment("Do MMMM YYYY") }}</span>
									</b-badge>
								</div>
							</div>
						</div>
						<div class="col-1 d-flex justify-content-end">
							<b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" class="btn-icon rounded-circle">
								<feather-icon icon="ChevronRightIcon" />
							</b-button>
						</div>
					</div>
				</div>
			</router-link>
		</div>
	</div>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BFormInput, BInputGroup, BInputGroupPrepend, BRow, BCol, BButton, BBadge } from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";

export default {
	components: {
		BRow,
		BCol,
		BCard,
		BCardHeader,
		BCardBody,
		BFormInput,
		BInputGroup,
		BInputGroupPrepend,
		BButton,
		BBadge,
		vSelect,
		flatPickr,
	},
	directives: {
		Ripple,
	},
	data() {
		return {
			plans: {},
			users: [],
			planType: null,
			filters: {
				planType: null,
				publishedActivities: null,
				bankAccountAdded: null,
				bookingsReceived: {
					from: 0,
					to: 100,
				},
				signedUp: null,
				signedUpCustom: null,
				search: null,
				customDomain: null,
			},
			planTypeOptions: [
				{ label: "Free", value: "free" },
				{ label: "Paid", value: "paid" },
			],
			publishedActivitiesOptions: [
				{ label: "Yes", value: "yes" },
				{ label: "No", value: "no" },
			],
			bankAccountAddedOptions: [
				{ label: "Yes", value: "yes" },
				{ label: "No", value: "no" },
			],
			signedUpOptions: [
				{ label: "Last 7 Days", value: "lastseven" },
				{ label: "Last 30 Days", value: "lastthirty" },
				{ label: "This Month", value: "thismonth" },
				{ label: "Last Month", value: "lastmonth" },
				{ label: "This Year", value: "thisyear" },
				{ label: "Last 365 Days", value: "last365days" },
				{ label: "All Time", value: "all" },
				{ label: "Custom", value: "custom" },
			],
			customDomainOptions: [
				{ label: "Added", value: "added" },
				{ label: "DNS Connected", value: "connected" },
				{ label: "Enabled", value: "enabled" },
			],
			loading_export: false,
		};
	},
	watch: {
		filters: {
			handler: function (newFilters, oldFilters) {
				if (newFilters.signedUp === "custom") {
					if (newFilters.signedUpCustom && newFilters.signedUpCustom.includes(" to ")) {
						this.fetchUsers();
					}
				} else {
					if (!newFilters.signedUp) {
						newFilters.signedUpCustom = null;
					}
					this.fetchUsers();
				}
			},
			deep: true,
		},
	},
	mounted() {
		this.plans = this.$plans();
		this.fetchUsers();
	},
	methods: {
		resetBookings() {
			this.filters.bookingsReceived.from = 0;
			this.filters.bookingsReceived.to = 100;
		},
		exportUsers() {
			this.loading_export = true;
			const user_ids = [];
			this.users.forEach((user) => {
				user_ids.push(user.id);
			});
			this.$http.get(`/users/${user_ids.join(",")}/export`).then((res) => {
				const items = res.data;
				const data = [];
				items.forEach((item) => {
					let user = {
						...item,
						...item.bookings,
						...item.activities,
						...item.coupons,
						...item.others,
					};
					delete user.operator;
					delete user.bookings;
					delete user.activities;
					delete user.coupons;
					delete user.others;
					data.push(user);
				});
				let encodedUri = encodeURI("data:text/csvcharset=utf-8," + this.$papa.unparse(data));
				let link = document.createElement("a");
				link.setAttribute("href", encodedUri);
				link.setAttribute("download", `users-${new Date().getTime()}.csv`);
				document.body.appendChild(link);
				link.click();
				this.loading_export = false;
			});
		},
		fetchUsers() {
			const params = {};
			if (this.filters.planType) {
				params.plan_type = this.filters.planType;
			}
			if (this.filters.publishedActivities) {
				params.published_activities = this.filters.publishedActivities;
			}
			if (this.filters.bankAccountAdded) {
				params.bank_added = this.filters.bankAccountAdded;
			}
			if (this.filters.signedUp) {
				if (this.filters.signedUp == "custom") {
					if (this.filters.signedUpCustom) {
						const dates = this.filters.signedUpCustom.split(" to ");
						params.signedup_from = this.$moment(dates[0], "YYYY-MM-DD").unix();
						params.signedup_to = this.$moment(dates[1], "YYYY-MM-DD").unix();
					}
				} else {
					if (this.filters.signedUp === "lastseven") {
						params.signedup_from = this.$moment().subtract(7, "d").unix();
						params.signedup_to = this.$moment().unix();
					} else if (this.filters.signedUp === "lastthirty") {
						params.signedup_from = this.$moment().subtract(30, "d").unix();
						params.signedup_to = this.$moment().unix();
					} else if (this.filters.signedUp === "thismonth") {
						params.signedup_from = this.$moment().startOf("month").unix();
						params.signedup_to = this.$moment().unix();
					} else if (this.filters.signedUp === "lastmonth") {
						params.signedup_from = this.$moment().startOf("month").subtract(1, "M").unix();
						params.signedup_to = this.$moment().endOf("month").subtract(1, "M").unix();
					} else if (this.filters.signedUp === "thisyear") {
						params.signedup_from = this.$moment().startOf("year").unix();
						params.signedup_to = this.$moment().unix();
					} else if (this.filters.signedUp === "last365days") {
						params.signedup_from = this.$moment().subtract(365, "d").unix();
						params.signedup_to = this.$moment().unix();
					} else if (this.filters.signedUp === "all") {
						params.signedup_from = 0;
						params.signedup_to = this.$moment().unix();
					}
				}
			}
			if (this.filters.search) {
				params.search = this.filters.search;
			}
			if (this.filters.bookingsReceived.from !== 0 || this.filters.bookingsReceived.to !== 100) {
				params.bookings_to = this.filters.bookingsReceived.to;
				params.bookings_from = this.filters.bookingsReceived.from;
			}
			if (this.filters.customDomain) {
				params.custom_domain = this.filters.customDomain;
			}
			this.$http.get("/users", { params }).then((res) => {
				this.users = res.data;
			});
		},
	},
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.adv-card {
	margin-bottom: 1rem;
	.card-body {
		padding: 1rem 1.5rem;
	}
}
.custom-signedup {
	[role="combobox"] {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}
	input[type="text"] {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		border-left: 0;
	}
}
.clear-badge {
	font-size: 70%;
	background-color: transparent !important;
	cursor: pointer;
}
</style>
